import PropTypes from "prop-types"
import React from "react"

// Components
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/Seo"

interface IProp {
    data: {
        allMdx: {
            totalCount: number,
            edges: [{
                node: {
                    frontmatter: {
                        path: string,
                        title: string,
                    },
                },
            }],
        },
        site: {
            siteMetadata: {
                title: string,
            },
        },
    },
    pageContext: {
        category: string,
    }
    location?: any
}

class Categories extends React.Component<IProp> {
    public render() {
        const { data } = this.props
        const { category } = this.props.pageContext
        const { edges, totalCount } = this.props.data.allMdx
        const title = data.site.siteMetadata.title
        const categoryHeader = `${totalCount} post${
            totalCount === 1 ? "" : "s"
        } categoryged with "${category}"`

        return (
            <Layout location={this.props.location} title={title}>
                <h1>{categoryHeader}</h1>
                <SEO title={categoryHeader} />
                <ul>
                    {edges.map(({ node }: any) => {
                        const { path } = node.frontmatter
                        return (
                            <li key={path}>
                                <Link to={path}>{title}</Link>
                            </li>
                        )
                    })}
                </ul>
                {/*
              This links to a page that does not yet exist.
              We'll come back to it!
            */}
                <Link to="/categories">All categories</Link>
            </Layout>
        )
    }
}
// const categories = ({ pageContext, data }) => {
//   const { category } = pageContext
//   const { edges, totalCount } = data.allMarkdownRemark
//   const categoryHeader = `${totalCount} post${
//     totalCount === 1 ? "" : "s"
//   } categoryged with "${category}"`

//   return (
//     <div>
//       <h1>{categoryHeader}</h1>
//       <ul>
//         {edges.map(({ node }) => {
//           const { path, title } = node.frontmatter
//           return (
//             <li key={path}>
//               <Link to={path}>{title}</Link>
//             </li>
//           )
//         })}
//       </ul>
//       {/*
//               This links to a page that does not yet exist.
//               We'll come back to it!
//             */}
//       <Link to="/categories">All categories</Link>
//     </div>
//   )
// }

export default Categories

export const pageQuery = graphql`
    query($category: String) {
        site {
            siteMetadata {
                title
                description
            }
        }
        allMdx(
            limit: 2000
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { categories: { in: [$category] } } }
        ) {
            totalCount
            edges {
                node {
                    frontmatter {
                        title
                        path
                    }
                }
            }
        }
    }
`
